import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import ContactUsCard from "../../Cards/ContactUs2";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationIcon from "@mui/icons-material/LocationOn";

const Section2 = ({ brand }) => {
  return (
    <Container>
      <ContactUs>
        <ContactUsCard
          brand={brand}
          Icon={PhoneIcon}
          title="GSM NUMARAMIZ"
          detail={brand.phone?.mobile}
        />
        <ContactUsCard
          brand={brand}
          Icon={PhoneIcon}
          title="OFİS NUMARAMIZ"
          detail="0212 557 88 30"
        />
        <ContactUsCard
          brand={brand}
          Icon={LocationIcon}
          title="HİZMET ALANI"
          detail="Bahçelievler Güngören Bağcılar Bakirköy Fatih Ataköy Halkalı Küçükçekmece Topkapı Avcılar"
        />
      </ContactUs>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: { xs: "flex", lg: "none" },
    justifyContent: "center",
    p: 2.4,
    my: "4%",
  })
);
const ContactUs = styled("div")(
  sx({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  })
);

export default Section2;
