import { experimental_sx as sx, styled } from "@mui/system";
import React from "react";
import PhoneIcon from "@mui/icons-material/PhoneEnabled";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactButton from "../ContactButton";
import { Box } from "@mui/material";

const Header = ({ brand }) => {
  const wp = brand.phone?.wp.replace(/\s+/g, "").trim();
  const mobile = brand.phone?.mobile.replace(/\s+/g, "").trim();

  return (
    <Container
      sx={{
        bgcolor: brand.colors?.primary,
        borderBottom: `5px solid ${brand.colors?.secondary}`,
      }}
    >
      <InnerContainer>
        <NavLogo>{brand.name?.toUpperCase()}</NavLogo>
        <Box sx={{ ml: "auto", display: "flex", gap: "20px" }}>
          <ContactButton bgcolor={"#d40000"} href={`tel:+9${mobile}`}>
            <PhoneIcon sx={{ color: "#fff", mr: { sm: "0px", md: "6px" } }} />
            <Text>{brand.phone?.mobile}</Text>
          </ContactButton>
          <ContactButton
            bgcolor={"#25D35A"}
            href={`https://wa.me/9${wp}?text=Merhaba, Acil çilingir lazım. Yardımcı olur musunuz?`}
            targetBlank
          >
            <WhatsAppIcon
              sx={{ color: "#fff", mr: { sm: "0px", md: "6px" } }}
            />
            <Text>{brand.phone?.wp}</Text>
          </ContactButton>
        </Box>
      </InnerContainer>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 10,
  })
);
const InnerContainer = styled("div")(
  sx({
    maxWidth: 1140,
    mx: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    p: 2,
  })
);
const NavLogo = styled("h1")(
  sx({
    color: "#fff",
    fontSize: { xs: "30px", md: "32px" },
  })
);
const Text = styled("span")(
  sx({
    display: { xs: "none", md: "flex" },
  })
);

export default Header;
