import { Typography } from "@mui/material";
import { experimental_sx as sx, styled } from "@mui/system";
import React, { useState } from "react";

const Details2 = ({ Icon, title, detail, brand }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      sx={{
        bgcolor: isHover && "#fff",
        boxShadow: isHover && "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.4s ease",
        borderRadius: 2,
      }}
    >
      <Icon
        fontSize="large"
        sx={{
          color: brand.colors?.secondary,
          width: 50,
          height: 50,
          mr: "10%",
          transition: "all 0.4s ease",
          transform: isHover && "scaleX(-1)",
        }}
      />
      <Content>
        <Typography
          component="h3"
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            borderLeft: `4px solid ${brand.colors?.secondary}`,
            pl: "4%",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#111111",
          }}
          lineHeight={1.7}
        >
          {detail}
        </Typography>
      </Content>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    width: { xs: "100%", md: 350 },
    // height: 220,
    p: 2,
  })
);
const Content = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  })
);

export default Details2;
