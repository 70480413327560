import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { ButtonBase } from "@mui/material";

const ContactButton = ({ children, bgcolor, href, targetBlank }) => {
  return (
    <Button
      sx={{ bgcolor }}
      component="a"
      href={href}
      target={targetBlank ? "_blank" : undefined}
    >
      {children}
    </Button>
  );
};

const Button = styled(ButtonBase)(
  sx({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    "&:hover": { bgcolor: "#0a6887" },
    transition: "all 0.3s ease",
    p: 1.4,
    borderRadius: 2,
  })
);

export default ContactButton;
