import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { Typography } from "@mui/material";
import Details2 from "../../Cards/Details2";
import PhoneIcon from "@mui/icons-material/PhoneIphone";
import KeyIcon from "@mui/icons-material/VpnKey";
import CarIcon from "@mui/icons-material/DirectionsCar";
import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";

const Section4 = ({ brand }) => {
  return (
    <Container>
      <InnerContainer>
        <Header>
          <Typography
            component="h2"
            sx={{
              fontSize: "36px",
              fontWeight: "bold",
            }}
          >
            Acil Durumlar
          </Typography>
          <Typography
            component="p"
            sx={{ mr: { xs: "0", lg: "35%" } }}
            lineHeight={1.7}
          >
            Evinizin veya aracınızın anahtarını kaybettiğinizde, kapıda
            kaldığınızda, üzerinde kilit bulunan herhangi bir sistemi
            açamadığınızda bizi arayın size yardımcı olalım.
          </Typography>
        </Header>
        <Cards>
          <Details2
            brand={brand}
            Icon={PhoneIcon}
            title="7/24 Ulaşım ve Hizmet"
            detail="Firmamıza 7/24 ulaşım sağlayıp çilingir servisi alabilir sorununuzu giderebilirsiniz. Her türlü kapı kilit işleriniz itina ile yapılır."
          />
          <Details2
            brand={brand}
            Icon={KeyIcon}
            title="Anahtar Hizmeti"
            detail="Servisimiz tüm anahtar sorunlarında sizlerin yanında ve problem neyse onu çözmenin peşinde. Anahtarcı . Arayın yardımcı olalım."
          />
          <Details2
            brand={brand}
            Icon={CarIcon}
            title="Oto Çilingir Hizmeti"
            detail="Araçlarınız firmamız tarafından yetkili ve profesyonel kadro ile zararsız açılmaktadır. Bizler araçlarınızı en uygun şekilde açıyoruz."
          />
          <Details2
            brand={brand}
            Icon={CheckIcon}
            title="Uygun Fiyat"
            detail="Tüm servislerimizde siz kıymetli müşterilerimize uygun çilingir hizmeti ve fiyatını belirliyoruz. Sadece bütçenizi belirleyin."
          />
          <Details2
            brand={brand}
            Icon={LockIcon}
            title="Kapı Kilit Hizmeti"
            detail={`${brand.name?.toUpperCase()} sizlere kapı kilit servisini en iyi ve kaliteli şekilde sunmakta ve işleme koymaktadır. Lütfen iletişime geçin.`}
          />
          <Details2
            brand={brand}
            Icon={SettingsIcon}
            title="Yüksek Güvenlikli Kilit"
            detail="Dünya Devi Olan Kilit Firmalarının Yetkili Bayisi Olarak Sizlerinde Kapılarınızı Güvende Olması İçin Çalışıyoruz."
          />
        </Cards>
      </InnerContainer>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    mt: "4%",
    p: 2.4,
    bgcolor: "#f9f9f9",
  })
);
const InnerContainer = styled("div")(
  sx({
    maxWidth: 1140,
    mx: "auto",
  })
);
const Cards = styled("div")(
  sx({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "15px",
    mt: "4%",
  })
);
const Header = styled("div")(
  sx({
    display: "flex",
    flexWrap: "wrap",
    mr: "auto",
    flexDirection: "column",
  })
);

export default Section4;
