import React, { useState } from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { Typography } from "@mui/material";

const ContactUsCard = ({ Icon, title, detail, brand }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      sx={{ borderBottom: `4px solid ${brand.colors?.secondary}` }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <IconContainer
        sx={isHover && { bgcolor: brand.colors?.secondary, color: "#fff" }}
      >
        <Icon
          sx={{
            color: isHover ? "#fff" : brand.colors?.secondary,
            transition: "all 0.3s ease",
          }}
          fontSize="large"
        />
      </IconContainer>
      <CardBody>
        <Typography
          sx={{ fontSize: "17px", fontWeight: "bold", color: "black" }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "black" }}>
          {detail}
        </Typography>
      </CardBody>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    alignItems: "center",
    gap: "14px",
    color: "#fff",
    bgcolor: "#fff",
    width: { xs: "100%", md: "360px" },
    height: "200px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    borderRadius: 2,
  })
);
const IconContainer = styled("div")(
  sx({
    width: "65px",
    height: "65px",
    bgcolor: "#fff",
    transition: "all 0.3s ease",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ml: "30px",
  })
);
const CardBody = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
  })
);

export default ContactUsCard;
