import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { ButtonBase, Typography } from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowForwardIos";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper";

const Footer = ({ brand }) => {
  const items = [
    {
      src: "/assets/partner1.png",
    },
    {
      src: "/assets/partner2.png",
    },
    {
      src: "/assets/partner3.png",
    },
    {
      src: "/assets/partner4.png",
    },
    {
      src: "/assets/partner1.png",
    },
    {
      src: "/assets/partner2.png",
    },
    {
      src: "/assets/partner3.png",
    },
    {
      src: "/assets/partner4.png",
    },
    {
      src: "/assets/partner1.png",
    },
    {
      src: "/assets/partner2.png",
    },
    {
      src: "/assets/partner3.png",
    },
    {
      src: "/assets/partner4.png",
    },
  ];
  const mobile = brand.phone?.mobile.replace(/\s+/g, "").trim();

  return (
    <Container>
      <InnerContainer>
        <Background src="/assets/background2.jpg" alt="cilingir" />
        <Content sx={{ bgcolor: "transparent", p: 2, flexWrap: "wrap" }}>
          <Typography
            component={"h3"}
            sx={{
              color: "#fff",
              fontSize: { xs: "18px", lg: "36px" },
              fontWeight: "bold",
              maxWidth: 850,
              textAlign: { xs: "center", lg: "left" },
            }}
          >
            {`Hızlı, kaliteli, profesyonel hizmet için Acil Çilingir ${brand.name?.toUpperCase()}`}{" "}
            arayın
          </Typography>
          <CallButton
            sx={{ bgcolor: brand.colors?.secondary }}
            component="a"
            href={`tel:+9${mobile}`}
          >
            HEMEN ARA
            <ArrowIcon sx={{ color: "#fff" }} />
          </CallButton>
        </Content>
      </InnerContainer>
      <InnerContainer>
        <Background src="/assets/background3.jpg" alt="cilingirci" />
        <Content sx={{ p: "40px" }}>
          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            slidesPerView={window.innerWidth < 900 ? 1 : 4}
            spaceBetween={30}
            modules={[Pagination, Autoplay]}
          >
            {items.map((item, i) => (
              <SwiperItem
                sx={{ "&:hover": { borderColor: brand.colors?.secondary } }}
                key={i}
              >
                <img src={item.src} alt={i} />
              </SwiperItem>
            ))}
          </Swiper>
        </Content>
      </InnerContainer>
      <Copyright>
        <Typography sx={{ whiteSpace: "nowrap" }}>
          {`Telif Hakkı © ${new Date().getFullYear()} ${brand.name?.toUpperCase()} | Geliştirici`}
        </Typography>
        <Developer href="https://metehanmutlu.com" target="_blank">
          Mete Mutlu
        </Developer>
      </Copyright>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
  })
);
const InnerContainer = styled("div")(
  sx({
    display: "flex",
    width: "100%",
    height: 190,
    position: "relative",
  })
);
const Background = styled("img")(
  sx({
    width: "100%",
    height: "100%",
  })
);
const Content = styled("div")(
  sx({
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: "auto",
    bgcolor: "rgba(0, 0, 0, 0.8)",
  })
);
const CallButton = styled(ButtonBase)(
  sx({
    color: "#fff",
    px: 4,
    py: 1.2,
    borderRadius: 5,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  })
);
const SwiperItem = styled(SwiperSlide)(
  sx({
    border: "2px solid gray",
    transition: "all 0.4s ease",
    width: "255px",
    height: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);
const Copyright = styled("div")(
  sx({
    bgcolor: "#1d1a1a",
    color: "#fff",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    p: 3,
    // fontSize: { xs: "14px", md: "18px" },
  })
);
const Developer = styled("a")(
  sx({
    textDecoration: "none",
    color: "#0693e3",
    whiteSpace: "nowrap",
  })
);

export default Footer;
