import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { Typography } from "@mui/material";

const DetailsCard = ({ Icon, title, detail, brand }) => {
  return (
    <Container>
      <IconContainer sx={{ bgcolor: brand.colors?.secondary }}>
        <Icon fontSize="large" sx={{ color: "#fff" }} />
      </IconContainer>
      <Typography
        component={"h3"}
        sx={{ fontSize: "18px", fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: "#969696", fontSize: "16px" }}>
        {detail}
      </Typography>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: { xs: "100%", md: 350 },
    height: 350,
    boxShadow: "0px 0px 40px 0px rgb(0 0 0 / 10%)",
    p: "40px",
    transition: "all 0.4s ease",
    "&:hover": { transform: "scale(1.1)" },
  })
);
const IconContainer = styled("div")(
  sx({
    width: "65px",
    height: "65px",
    transition: "all 0.3s ease",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);
export default DetailsCard;
