import { experimental_sx as sx, styled } from "@mui/system";
import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";
import config from "./config";

function App() {
  const [brand, setBrand] = useState({});

  useEffect(() => {
    const { brands } = config;
    setBrand(brands[1]);
  }, []);

  useEffect(() => {
    document.title = brand.name?.toUpperCase();
  }, [brand]);

  return (
    <Container>
      <Header brand={brand} />
      <Main brand={brand} />
      <Footer brand={brand} />
    </Container>
  );
}

const Container = styled("div")(sx({}));

export default App;
