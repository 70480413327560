import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import Section1 from "../Sections/Section1";
import Section2 from "../Sections/Section2";
import Section3 from "../Sections/Section3";
import Section4 from "../Sections/Section4";

const Main = ({ brand }) => {
  return (
    <Container>
      <Section1 brand={brand} />
      <Section2 brand={brand} />
      <Section3 brand={brand} />
      <Section4 brand={brand} />
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
  })
);

export default Main;
