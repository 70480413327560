import React, { useState } from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { Typography } from "@mui/material";

const ContactUsCard = ({ Icon, title, detail, brand }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Container
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      sx={isHover && { bgcolor: brand.colors?.primary, transition: "all 0.3s ease" }}
    >
      <IconContainer sx={isHover && { bgcolor: brand.colors?.secondary, color: "#fff" }}>
        <Icon
          sx={{
            color: isHover ? "#fff" : brand.colors?.secondary,
            transition: "all 0.3s ease",
          }}
          fontSize="large"
        />
      </IconContainer>
      <CardBody>
        <Typography sx={{ fontSize: "17px", fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: "14px", color: "rgba(231, 231, 231, 0.82)" }}
        >
          {detail}
        </Typography>
      </CardBody>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    gap: "14px",
    color: "#fff",
    bgcolor: "rgba(35, 44, 57, 0.47)",
    border: "1px solid #1E253D",
    width: "360px",
    height: "128px",
  })
);
const IconContainer = styled("div")(
  sx({
    width: "65px",
    height: "65px",
    bgcolor: "#fff",
    transition: "all 0.3s ease",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ml: "30px",
  })
);
const CardBody = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
  })
);

export default ContactUsCard;
