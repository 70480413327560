import React from "react";
import { experimental_sx as sx, styled } from "@mui/system";
import { Typography } from "@mui/material";
import DetailsCard from "../../Cards/Details";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PhoneIcon from "@mui/icons-material/Phone";
import LockIcon from "@mui/icons-material/Lock";

const Section3 = ({ brand }) => {
  return (
    <Container>
      <InnerContainer>
        <Header>
          <Typography
            component="h2"
            sx={{
              fontSize: "36px",
              fontWeight: "bold",
            }}
          >
            Çilingir Servisi
          </Typography>
          <Typography
            component="p"
            sx={{ mr: { xs: "0", lg: "35%" } }}
            lineHeight={1.7}
          >
            İstanbul’un Anadolu ve Avrupa yakasında, 7/24 servisi olarak hizmet
            vermektedir. Çelik kapı, demir kapı ya da ahşap kapı türleri için
            çilingir hizmeti sunan servisimiz; oda kapısı, garaj kapısı ya da
            otomobil kapısı gibi farklı alanlara yönelik kilit açma hizmeti de
            sunmaktadır.
          </Typography>
        </Header>
        <Cards>
          <DetailsCard
            brand={brand}
            Icon={PhoneIphoneIcon}
            title={"Nöbetçi Çilingir"}
            detail={`Günün her saatinde çilingir servisini arayabilir, hizmet talep edebilirsiniz, İstanbul’un her semtinde hizmet veren ${brand.name?.toUpperCase()} ekibinden yardım al.`}
          />
          <DetailsCard
            brand={brand}
            Icon={PhoneIcon}
            title={"Oto Çilingirciliği"}
            detail={
              "Kilidiniz içeride kaldıysa hemen bizi arayın, araba kilidini açmakta uzman olan çilingirimiz bu sorununuzu çözecektir."
            }
          />
          <DetailsCard
            brand={brand}
            Icon={LockIcon}
            title={"Kasa Çilingirciliği"}
            detail={
              "Kasa çilingirliği, tüm kasalarınızdan çilingir hizmet almak için bize web sitemizde bulunan cep numaramızdan bize ulaşabilirsiniz."
            }
          />
        </Cards>
      </InnerContainer>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    my: "1%",
    p: 2.4,
  })
);
const InnerContainer = styled("div")(
  sx({
    maxWidth: 1140,
    mx: "auto",
  })
);
const Header = styled("div")(
  sx({
    display: "flex",
    flexWrap: "wrap",
    mr: "auto",
    flexDirection: "column",
  })
);
const Cards = styled("div")(
  sx({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    mt: "4%",
    gap: "30px",
  })
);

export default Section3;
