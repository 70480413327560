const config = {
  brands: [
    {
      name: "Ali Usta",
      colors: {
        primary: "#0F1425",
        secondary: "#FF6A3E",
      },
      phone: {
        mobile: "0543 853 42 86",
        wp: "0532 573 43 82",
      },
    },
    {
      name: "Doğan Usta",
      colors: {
        primary: "#222831",
        secondary: "#51C4D3",
      },
      phone: {
        mobile: "0542 234 42 86",
        wp: "0532 573 43 82",
      },
    },
    {
      name: "Mehmet Usta",
      colors: {
        primary: "#2A363B",
        secondary: "#FF847C",
      },
      phone: {
        mobile: "0542 234 42 86",
        wp: "0532 573 43 82",
      },
    },
    {
      name: "Ahmet Usta",
      colors: {
        primary: "#0F1425",
        secondary: "#FF6A3E",
      },
      phone: {
        mobile: "0532 573 43 82",
        wp: "0532 573 43 82",
      },
    },
  ],
};
export default config;
