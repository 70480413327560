import React from "react";
import ArrowIcon from "@mui/icons-material/ArrowForwardIos";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationIcon from "@mui/icons-material/LocationOn";
import { ButtonBase, Typography } from "@mui/material";
import { experimental_sx as sx, styled } from "@mui/system";
import ContactUsCard from "../../Cards/ContactUs";

const Section1 = ({ brand }) => {
  const mobile = brand.phone?.mobile.replace(/\s+/g, "").trim();

  return (
    <Container>
      <Background src="/assets/background.png" alt="background" />
      <Content>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#fff",
            fontSize: { xs: "25px", md: "70px" },
          }}
        >
          ACİL ÇİLİNGİR
        </Typography>
        <Typography
          sx={{
            color: brand.colors?.secondary,
            fontSize: { xs: "25px", md: "70px" },
            fontWeight: "bold",
          }}
        >
          {brand.phone?.mobile}
        </Typography>
        <CallButton
          sx={{ bgcolor: brand.colors?.secondary }}
          component="a"
          href={`tel:+9${mobile}`}
        >
          HEMEN ARA
          <ArrowIcon sx={{ color: "#fff" }} />
        </CallButton>
        <InnerContactUs>
          <ContactUsCard
            brand={brand}
            Icon={PhoneIcon}
            title="GSM NUMARAMIZ"
            detail={brand.phone?.wp}
          />
          <ContactUsCard
            brand={brand}
            Icon={PhoneIcon}
            title="OFİS NUMARAMIZ"
            detail="0212 557 88 30"
          />
          <ContactUsCard
            brand={brand}
            Icon={LocationIcon}
            title="HİZMET ALANI"
            detail="Bahçelievler Güngören Bağcılar Bakirköy Fatih Ataköy Halkalı Küçükçekmece Topkapı Avcılar"
          />
        </InnerContactUs>
      </Content>
    </Container>
  );
};

const Container = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
    position: "relative",
  })
);
const Background = styled("img")(
  sx({
    width: "100%",
    height: "100%",
  })
);
const Content = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: "auto",
    bgcolor: "rgba(15, 20, 37, 0.8)",
  })
);
const CallButton = styled(ButtonBase)(
  sx({
    color: "#fff",
    px: 4,
    py: 1.2,
    borderRadius: 5,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
);
const InnerContactUs = styled("div")(
  sx({
    display: { xs: "none", lg: "flex" },
    gap: "16px",
    mt: "20%",
  })
);

export default Section1;
